<template>
  <div>
    <section class="header">
      <div class="contain">
        <div class="text_box">
          <strong>简单制作 高效运营</strong><br />
          <span>金融短视频专家，创作运营更方便</span>
        </div>
        <div class="bg_box">
          <div class="circle1"></div>
          <div class="circle2"></div>
        </div>
      </div>
    </section>
    <section class="contain">
      <div class="part part1">
        <div class="text_box">
          <strong class="text1">视频制作</strong><br />
          <span class="text2">video producing</span><br />
          <span class="text3">素材中心、数据可视化制作、视频编辑，简单的金融短视频生成</span>
        </div>
        <div class="bg"></div>
      </div>
      <div class="part part2">
        <div class="text_box">
          <strong class="text1">互动推广</strong><br />
          <span class="text2">Interactive marketing</span><br />
          <span class="text3">添加互动元素，玩法更多样，推广更省心</span>
        </div>
        <div class="bg"></div>
      </div>
      <div class="part part3">
        <div class="text_box">
          <strong class="text1">一键分发</strong><br />
          <span class="text2">A key distribution</span><br />
          <span class="text3">视频多渠道分发，高效运营</span>
        </div>
        <div class="bg"></div>
      </div>
      <div class="part part4">
        <div class="text_box">
          <strong class="text1">统计分析</strong><br />
          <span class="text2">statistical analysis</span><br />
          <span class="text3">行为追踪，用户分析，精准营销</span>
        </div>
        <div class="bg"></div>
      </div>
    </section>
  </div>
</template>
<script>
import anime from "animejs/lib/anime.es.js";
import ScrollReveal from "scrollreveal";
export default {
  data() {
    return {};
  },
  methods: {
    init() {
      ScrollReveal().reveal(".header .text_box, .part .text_box ", {
        origin: "left",
        distance: "1000px",
        duration: 1000,
        reset: true,
      });
      anime({
        targets: ".header .circle1",
        rotate: "-360deg",
        scale: [0.8, 0.8],
        duration: 5000,
        easing: "linear",
        loop: true,
      });
      anime({
        targets: ".header .circle2",
        rotate: "360deg",
        scale: [0.6, 0.6],
        duration: 5000,
        easing: "linear",
        loop: true,
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./product.less";
</style>
